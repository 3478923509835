<template>
  <el-dialog
    @open="dialogOpen"
    title="签到二维码"
    :visible="showCode"
    width="504px"
    class="dialog-vertical"
    @close="cancel"
  >
    <el-form
      v-loading="loading"
      :model="codeForm"
      label-width="0px"
      size="medium"
      @submit.native.prevent
    >
      <el-form-item>
        <div class="code">
          <div class="content-box">
            <div class="item-box">
              <div class="code-box" style="border-radius: 50%">
                <el-image fit="cover" :src="codeForm.mp"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codeForm.mp"
                  download="会员座位查询"
                >
                  下载小程序码
                </a>
              </el-button>
            </div>
            <div class="item-box">
              <div class="code-box">
                <el-image fit="cover" :src="codeForm.h5"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codeForm.h5"
                  download="签到码"
                >
                  下载 H5 微网站二维码
                </a>
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="链接" v-if="status == 1">
        <el-input disabled style="width: 75%" v-model="codeForm.link" :rows="3" placeholder="请输入链接">
        </el-input>
        <el-button style="margin-left: 10px" type="text" @click="copy(codeForm.link)">
          复制
        </el-button>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button size="small" type="primary" @click="cancel">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { copyText } from '@/base/utils/tool'
import { placeSearchCode } from '@/modules/activity/api/activity-detail/detail'
export default {
  name: 'place-search-code-dialog',
  props: ['showCode'],
  data() {
    return {
      codeForm: {
        mp: '',
        h5: '',
      },
      loading: false,
    }
  },
  created() {},
  methods: {
    dialogOpen() {
      console.log(this.$route.params.id)
      this.loading = true
      // 签到二维码
      placeSearchCode({ activity_id: this.$route.params.id })
        .then((res) => {
          this.codeForm.mp = res.data.mp
          this.codeForm.h5 = res.data.h5
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    cancel() {
      this.$emit('closeCode')
    },
    // 复制
    copy(url) {
      copyText(url).then((valid) => {
        this.$message.success('复制成功')
      })
    },
  },
}
</script>

<style scoped lang="scss">
.code-box {
  width: 210px;
  height: 210px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}

.code {
  .content-box {
    background-color: #f2f2f2;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .item-box {
      width: 180px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .code-box {
        width: 180px;
        height: 180px;
        margin: 10px 0;

        background-color: #fff;

        overflow: hidden;
      }
    }
  }
}
</style>
