<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="80px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader
            v-model="form.positionIds"
            :options="OrganizationList"
            :props="props"
            placeholder="架构/会内职务"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="座位号：" prop="seat_number">
          <el-input
            v-model="form.seat_number"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="用餐桌位号：" prop="table_number">
          <el-input
            v-model="form.table_number"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { exportInviteUser } from "../../api/activity-ranking/list";
import { positionLevel } from "@/modules/organization-admin/api/member/member-list";
export default {
  name: "ListFilter",
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      // 架构选择列表
      OrganizationList: [],
    };
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  created() {
    this.getParentToList();
  },
  methods: {
    onExport() {
      const postData = { ...this.form };
      return exportInviteUser(postData);
    },
    // 获取架构列表
    getParentToList() {
      positionLevel({ is_list_tree: 0, is_show_all: 1,data_auth_filter:0 })
        .then((res) => {
          console.log("positionLevel", res);
          const { data } = res;
          data.map((item) => {
            item.children = [];
          });
          this.OrganizationList = data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["templateForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
};
</script>
