<template>
  <div class="list-page">
    <!-- 顶部按钮 -->
    <slot name="top" />

    <!-- 温馨提示 -->
    <slot name="reminder" />

    <!-- 筛选面板 -->
    <slot
      name="filter"
      :idList="selectArr"
      :pageData="{ page_size: pageData.per_page, page: pageData.current_page }"
    />

    <!-- tabs 栏 -->
    <div v-if="tabArr && tabArr.length" class="tabs">
      <list-tabs
        v-model="filterForm.tab"
        :tabs="tabArr"
        @tab-click="handleTabClick"
      />
    </div>
    <div v-else class="tabs">
      <slot name="tabs" :loading="loading" />
    </div>

    <div class="list-content" v-loading="loading">
      <!-- 表格顶部按钮组 -->
      <div class="list-btn">
        <slot name="tabletop" :idList="selectArr" :list="tbody">
          <!-- 后备内容 -->
          <template v-if="filterForm.tab !== undefined">
            <!-- 回收站默认有还原按钮 -->
            <template v-if="filterForm.tab === 'deleted'">
              <el-button type="success" size="small" @click="putBackSelected"
                >还原</el-button
              >
            </template>
            <!-- 当有 tabArr 时，默认每个 tab 下都会有删除（不管是真删还是软删）按钮），如果不需要，需要手动声明名为 tabletop 的 slot 然后留空 -->
            <el-button
              v-if="
                typeof onDelete !== 'undefined' &&
                myHead &&
                myHead[0].type === 'selection'
              "
              size="small"
              type="danger"
              @click="deleteSelected"
              >删除</el-button
            >
          </template>
          <template v-else>
            <!-- 无 tabArr，检测到有删除方法，且支持多选操作，则自动显示删除按钮 -->
            <el-button
              v-if="
                typeof onDelete !== 'undefined' &&
                myHead &&
                myHead[0].type === 'selection'
              "
              size="small"
              type="danger"
              @click="deleteSelected"
              >删除</el-button
            >
          </template>
        </slot>
      </div>
      <!-- 表格 -->
      <!--                :max-height="tableHeight || undefined"-->
      <el-table
        ref="tableRef"
        :data="tbody"
        class="thead-light"
        :stripe="striped"
        style="width: 100%"
        :height="tableHeight || undefined"
        :default-sort="defaultSort"
        :span-method="spanMethod"
        :highlight-current-row="role === 'dialog'"
        :row-key="rowKey"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        @row-click="rowClick"
      >
        <template v-for="(th, index) in myHead">
          <!-- 勾选（多选） -->
          <el-table-column
            v-if="th.type === 'selection'"
            class="select-check"
            fixed
            type="selection"
            width="55"
          />
          <!-- 自己实现的单选，这个只是展示状态，通过 @row-click 实现 -->
          <el-table-column
            v-else-if="th.type === 'radio'"
            class="select-check"
            fixed
            width="55"
          >
            <template slot-scope="{ row }">
              <el-radio :label="row.id" :value="selectArr[selectArr.length - 1]"
                ><span></span
              ></el-radio>
            </template>
          </el-table-column>
          <!-- 序号 -->
          <el-table-column
            v-else-if="th.type === 'index'"
            type="index"
            width="85"
            :label="th.label"
          />
          <!-- 其他 -->
          <el-table-column
            v-else-if="
              th.visible !== undefined ? th.visible(null, filterForm) : true
            "
            :show-overflow-tooltip="
              th.tooltip === undefined ? true : th.tooltip
            "
            :prop="th.prop"
            :label="th.label"
            :fixed="th.type === 'operation' ? th.fixed || 'right' : false"
            :align="th.align || 'left'"
            :width="
              (th.getWidth !== undefined && th.getWidth(filterForm)) ||
              (!th.width &&
              filterForm.tab === 'deleted' &&
              th.type === 'operation'
                ? '130'
                : '') ||
              th.width ||
              ''
            "
            :min-width="th.minWidth || ''"
            :sortable="th.sortable"
            :sort-orders="th.sort_orders || ['ascending', 'descending', null]"
            :key="`${th.type || ''}_${th.prop || ''}_${index}`"
          >
            <template slot="header">
              {{ th.label }}
              <el-tooltip
                v-if="th.info"
                placement="top"
                :content="th.info"
                popper-class="my-list-thead-tip"
              >
                <i class="el-icon el-icon-info" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <!-- 自定义列表项遍历的内容，需要声明type值为slot，插槽名为 item_xxx -->
              <slot
                v-if="th.type === 'slot'"
                :name="'item_' + th.prop"
                :row="scope.row"
                :$index="scope.$index"
              />
              <!-- 复选框 -->
              <el-checkbox
                v-else-if="th.type === 'checkbox'"
                v-model="scope.row[th.prop]"
                :true-label="1"
                :false-label="0"
                @change="changeStatus(scope.row, th.prop)"
              />
              <!-- 排序输入框 -->
              <PopoverInputEdit
                v-else-if="th.prop === 'sort'"
                input-type="number"
                :validator="validator"
                v-model="scope.row[th.prop]"
                @confirm="changeStatus(scope.row, th.prop)"
              />
              <!-- 图片、图标 -->
              <list-image
                v-else-if="th.prop === 'avatar' && scope.row.avatar"
                fit="cover"
                :height="40"
                :width="40"
                border-radius="50%"
                :src="scope.row.avatar"
              />
              <!-- logo -->
              <list-image
                v-else-if="th.prop === 'logo' && scope.row.logo"
                fit="cover"
                :height="40"
                :width="40"
                border-radius="8px"
                :src="scope.row.logo"
              />
              <list-image
                v-else-if="
                  (th.type === 'icon' || th.type === 'image') &&
                  scope.row[th.prop]
                "
                fit="cover"
                :width="
                  th.iconWidth ||
                  th.imageWidth ||
                  th.size ||
                  th.iconSize ||
                  th.imageSize
                "
                :border-radius="th.borderRadius"
                :src="scope.row[th.prop]"
              />
              <!-- 状态指示点 -->
              <list-status-text
                v-else-if="
                  th.prop === 'status_text' ||
                  (th.prop && th.prop.includes('_status_text'))
                "
                :text="scope.row[th.prop]"
              />
              <template v-else-if="th.type === 'operation'">
                <el-button-group>
                  <!-- 推荐 -->
                  <!-- 在 thead 中 定义 operations 对象数组，如：[{command: 'edit', text: '编辑'}, {command: 'foo', text: 'bar'}] -->
                  <!-- 缺点：还原和删除也要显式定义，使用 visible 函数控制显示隐藏 -->
                  <template
                    v-if="th.operations !== undefined && th.operations.length"
                  >
                    <template v-for="op in th.operations">
                      <template
                        v-if="
                          op.visible === undefined ||
                          (op.visible !== undefined &&
                            op.visible(scope.row, filterForm))
                        "
                      >
                        <el-button
                          v-if="op.command === 'edit'"
                          :disabled="
                            op.disabled === undefined
                              ? false
                              : op.disabled(scope.row)
                          "
                          type="text"
                          size="small"
                          @click="handleRow('edit', scope.row)"
                          >{{ op.text || '编辑' }}</el-button
                        >
                        <el-button
                          v-else-if="
                            op.command === 'delete' || op.command === 'del'
                          "
                          :disabled="
                            op.disabled === undefined
                              ? false
                              : op.disabled(scope.row)
                          "
                          type="text"
                          size="small"
                          @click="handleRow('delete', scope.row)"
                          >{{ op.text || '删除' }}</el-button
                        >
                        <el-button
                          v-else-if="op.command === 'putback'"
                          :disabled="
                            op.disabled === undefined
                              ? false
                              : op.disabled(scope.row, filterForm)
                          "
                          type="text"
                          size="small"
                          @click="putBackRow(scope.row)"
                          >还原</el-button
                        >
                        <template v-else-if="op.command === 'more'">
                          <el-divider direction="vertical" />
                          <el-dropdown @command="handleRow($event, scope.row)">
                            <el-button
                              :disabled="
                                op.disabled === undefined
                                  ? false
                                  : op.disabled(scope.row)
                              "
                              type="text"
                              size="small"
                              >更多</el-button
                            >
                            <el-dropdown-menu slot="dropdown">
                              <template v-for="(item, opI) in op.operations">
                                <template
                                  v-if="
                                    item.visible === undefined ||
                                    (item.visible !== undefined &&
                                      item.visible(scope.row, filterForm))
                                  "
                                >
                                  <el-dropdown-item
                                    :key="opI"
                                    :command="item.command"
                                    >{{
                                      item.name || item.text
                                    }}</el-dropdown-item
                                  >
                                </template>
                              </template>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                        <el-button
                          v-else
                          :disabled="
                            op.disabled === undefined
                              ? false
                              : op.disabled(scope.row)
                          "
                          :loading="
                            op.loading !== undefined
                              ? op.loading(scope.row, filterForm)
                              : false
                          "
                          type="text"
                          size="small"
                          @click.stop="handleRow(op.command, scope.row)"
                          >{{ op.text }}</el-button
                        >
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <!-- 自定义操作按钮，会影响所有tab，需要在slot里面自行判断，或者改用上面的 thead 方式 -->
                    <slot
                      name="operation"
                      :row="scope.row"
                      :$index="scope.$index"
                    />
                    <slot name="operation_default">
                      <template v-if="filterForm.tab !== undefined">
                        <!-- 回收站自带还原按钮 -->
                        <el-button
                          v-if="filterForm.tab === 'deleted'"
                          type="text"
                          size="small"
                          @click="putBackRow(scope.row)"
                          >还原</el-button
                        >
                        <!-- TODO: 移除自带的编辑按钮 -->
                        <!--                      <el-button v-if="filterForm.tab !== 'deleted'" type="text" size="small" @click="editRow(scope.row)">编辑</el-button>-->
                        <!-- 有 tabArr 情况下，默认自带删除，如果不需要，请不要传入 onDelete prop -->
                        <el-button
                          v-if="typeof onDelete !== 'undefined'"
                          type="text"
                          size="small"
                          @click="deleteRow(scope.row)"
                          >删除</el-button
                        >
                      </template>
                    </slot>
                  </template>
                </el-button-group>
              </template>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      v-show="!loading"
      :total="pageData.total || 0"
      :page-size="initPageSize"
      :page="pageData.current_page"
      show-prev-next
      :change="changePage"
    />
    <!-- 可以放置弹窗组件 -->
    <slot />
  </div>
</template>

<script>
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import Pagination from '@/base/components/Default/Pagination'
import ListImage from '@/base/components/List/ListImage'
import ListStatusText from '@/base/components/List/ListStatusText'
import query from '../mixins/query'

export default {
  name: 'ListLayout',
  components: { ListStatusText, ListImage, Pagination, PopoverInputEdit },
  mixins: [query],
  props: {
    filterForm: {
      type: Object,
      default: function () {
        return {}
      },
      required: true,
    },
    tabArr: Array,
    thead: {
      type: [Array, Object],
      required: true,
    },
    onFetch: Function,
    /* 点击列表项编辑按钮 */
    onEdit: Function,
    /* 通用还原 */
    onPutBack: Function,
    /* 通用删除，有 tabArr 时未传入该属性则不显示删除按钮 */
    onDelete: Function,
    /* 开关、勾选框之类的 */
    onToggle: Function,
    /* 修改排序之类的 */
    onUpdate: Function,
    tableHeight: String,
    onSpan: Function,
    spanMethod: Function,
    switchRouteWhenTabChange: {
      type: [Boolean, String],
      default: false,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    /* 默认处在页面中，而不是弹窗中 */
    role: {
      type: String,
      default: 'page', // list | dialog
    },
    // 缓存离开前的筛选条件
    storageFilterForm: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    // 排序 key
    sortAlias: Object,
    // 默认的 key 为 id
    defaultSortKey: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      loading: false, // 加载
      pageData: {
        page_size: 15,
      },
      tbody: [],
      tbodyCached: [], // 历史访问列表数据
      selectArr: [], // 勾选的数组id
      validator: {
        type: 'integer',
        min: 0,
        message: '最小不能低于0的整数',
        trigger: 'blur',
      },
      initPageSize: 15,
    }
  },
  computed: {
    /* 表格排序小三角选中样式 */
    defaultSort() {
      if (this.filterForm.order_by === undefined) return {}
      // 按后端返回的顺序
      if (this.filterForm.is_desc === -1 || this.filterForm.order_by === 'id')
        return {
          order: null,
          prop: this.filterForm.order_by,
        }
      return {
        order: this.filterForm.is_desc ? 'descending' : 'ascending',
        prop: this.filterForm.order_by,
      }
    },
    myHead() {
      // console.log(Array.isArray(this.thead) ? this.thead : this.thead[this.filterForm.tab])
      return Array.isArray(this.thead)
        ? this.thead
        : this.thead[this.filterForm.tab]
    },
  },
  created() {
    // console.log('layout created')
    if (this.$route.params.tab || this.$route.query.tab) {
      this.filterForm.tab = this.$route.params.tab || this.$route.query.tab
    }
    this.initPageSize = this.filterForm.page_size

    if (this.$route.query.q) {
      // 等待 listFilter created，应该可以使用 nextTick 等待，此部分由 mixin 完成
    } else {
      // this.getList() // 获取列表数据，为了不影响 resetFields() 功能，都交给 mixin 完成处理
    }

    if (this.storageFilterForm) {
      // 忽略
    } else {
      this.getList()
    }
  },
  methods: {
    /* 处理自定义列表操作按钮事件 */
    handleRow(command, row) {
      if (command === 'edit' && this.onEdit !== undefined) {
        this.onEdit(row)
      } else if (command === 'delete' && this.onDelete !== undefined) {
        this.deleteRow(row)
      } else if (command === 'putback' && this.onPutBack !== undefined) {
        this.putBackRow(row)
      } else {
        const { data, ...others } = this.pageData
        this.$emit('command', { command, row, pageData: others })
      }
    },
    // 获取精神列表
    getList(pageData = {}) {
      this.tbody = []
      this.pageData = {}
      this.loading = true
      this.onFetch(Object.assign({}, this.filterForm, pageData))
        .then((res) => {
          const { data } = res
          this.tbody = data.data || data
          this.tbodyCached.push(...this.tbody)

          if (data.data) {
            const { list, ...others } = data
            this.pageData = others
          } else {
            this.pageData = {
              page_size: pageData.page_size,
            }
          }

          /* TODO：如无需要，考虑移除这段代码 */
          if (this.onSpan !== undefined) {
            this.tbody = this.onSpan(this.tbody)
          }

          this.$emit('count', data.data ? data.total : data.length)
          this.$emit('stats', data)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 批量删除
    deleteSelected() {
      if (this.selectArr.length === 0) {
        this.$message.info('没有检测到勾选的数据！')
      } else if (this.selectArr.length > 1) {
        if (
          ['normal', 'checked', 'checking', 'rejected'].includes(
            this.filterForm.tab
          )
        ) {
          this.$msgbox
            .confirm('确定要将这些数据移入回收站吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              this.handleDelete(this.selectArr)
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将这些数据永久删除吗？', '提示', {
              type: 'error',
            })
            .then((res) => {
              this.handleDelete(this.selectArr)
            })
            .catch((err) => {})
        }
      } else {
        this.deleteRow({ id: this.selectArr[0] })
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (
        ['normal', 'checked', 'checking', 'rejected'].includes(
          this.filterForm.tab
        )
      ) {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete(row.id)
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete(row.id)
          })
          .catch((err) => {})
      }
    },
    // 执行数据删除
    handleDelete(id, tab = '', callback = () => {}) {
      this.loading = true
      this.onDelete(
        this.getIdList(id),
        tab === 'deleted' || this.filterForm.tab === 'deleted'
      )
        .then((res = {}) => {
          if (res.msg) this.$message.success(res.msg)
          this.getList()
          if (callback) callback()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 批量还原数据
    putBackSelected() {
      if (this.selectArr.length === 0) {
        this.$message.info('没有检测到勾选的数据！')
      } else if (this.selectArr.length > 1) {
        this.$msgbox
          .confirm('确定要将选中数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handlePutBack(this.selectArr)
          })
          .catch((err) => {})
      } else {
        this.putBackRow({ id: this.selectArr[0] })
      }
    },
    // 点击列表还原
    putBackRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.handlePutBack(row.id)
        })
        .catch((err) => {})
    },
    /**
     * 执行还原
     * @param{String|Number|Array} id
     */
    handlePutBack(id) {
      this.loading = true
      this.onPutBack(this.getIdList(id))
        .then((res) => {
          this.$message.success(res.msg)
          this.getList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    //表格排序
    sortChange(column) {
      // this.filterForm.is_desc = !column.order
      //   ? -1
      //   : column.order === 'ascending'
      //   ? 0
      //   : 1
      this.filterForm.is_desc = !column.order
        ? 1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = !column.order
        ? (this.sortAlias && this.sortAlias[this.defaultSortKey]) ||
          this.defaultSortKey
        : (this.sortAlias && this.sortAlias[column.prop]) || column.prop
      this.getList()
    },
    /**
     * 在弹窗中（role 为 dialog）的列表才支持行点击处理事件
     */
    rowClick(row) {
      if (this.role === 'dialog') {
        // 是否多选
        const multiple = !this.thead.find((th) => th.type === 'radio')

        if (multiple) {
          // toggle：选中的就取消，未选的就选中
          this.$refs.tableRef.toggleRowSelection(row)
        } else {
          if (this.selectArr.includes(row.id)) {
            // 单选的时候，保持选中就行了，不能取消

            // 取消勾选，为的是把最后一次的选择放数组最后面
            const rowIndex = this.selectArr.indexOf(row.id)
            this.selectArr.splice(rowIndex, 1)
          } else {
          }
          // 勾选，放最后面
          this.selectArr.push(row.id)
        }

        console.log(this.selectArr)
      }
    },
    // 弹窗模式下，打开时可选择清空上一次的选择，需要外部主动调用
    clearSelection() {
      this.$refs.tableRef.clearSelection()
      this.selectArr = []
      this.setCurrent()
    },
    // 当前高亮行，不是刚需
    setCurrent(row) {
      this.$refs.tableRef.setCurrentRow(row)
    },
    // 如果在弹窗外面拿到了id，需要拿其他信息，可以调用这个方法，不是很靠谱
    getDetailById(id) {
      if (!this.tbodyCached?.length) return null
      return this.tbodyCached.findLast((td) => {
        return td.id === id
      }) // 找不到就是这一页数据找不到咯
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {}
      data.id = e.id
      if (key === 'sort') {
        data[key] = e[key]
      } else {
        data[key] = e[key] ? 1 : 0
      }
      this.loading = true
      if (key === 'is_show' || key === 'status') {
        // 更新状态
        this.onToggle(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.getList()
          })
          .catch(() => {
            this.getList()
          })
      } else {
        // 更新排序状态
        this.onUpdate(data)
          .then((res) => {
            this.$message.success(res.msg)
            this.getList()
          })
          .catch(() => {
            this.getList()
          })
      }
    },
    // 分页查询
    changePage(e) {
      this.filterForm.page_size = e.page_size
      this.getList(e)
    },
    // tab切换事件
    handleTabClick(e) {
      /* 注意：还需要到 src/base/components/Default/SidebarItem.vue 下配置 defaultTab */
      if (this.switchRouteWhenTabChange) {
        // if (e.paneName !== this.filterForm.tab) {
        this.$router
          .replace({
            name: this.switchRouteWhenTabChange,
            params: { tab: e.paneName },
            query: { ...this.$route.query },
          })
          .catch(console.log)
        // }
        // this.filterForm.tab = e.paneName
        // this.filterForm.page = 1
        // this.$store.commit("setRefresh", true);
      }
      // else {
      this.filterForm.tab = e.paneName
      this.filterForm.page = 1
      // handletab tab切换更改order_by
      this.$emit('handletab', this.filterForm.tab)
      this.getList()
      // }
    },
    // 返回要操作的id数组
    getIdList(id) {
      let idList
      if (typeof id === 'number' || typeof id === 'string') {
        /* 单个 */
        idList = [id]
      } else {
        /* 多选 */
        idList = id
      }
      return idList
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-filter {
  margin-bottom: $space;
}

.list-content {
  min-height: 400px;

  ::v-deep.el-table {
    .is-sortable {
      &.ascending {
        .sort-caret.ascending {
          border-bottom-color: $primary;
        }
      }

      &.descending {
        .sort-caret.descending {
          border-top-color: $primary;
        }
      }
    }
  }
}
</style>
