<template>
  <div class="content-box">
    <el-dialog
      :title="isEdit ? '编辑' : '添加'"
      :visible="is_show"
      width="600px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialogFn"
    >
      <el-form
        size="mini"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="120px"
        ref="form"
        v-loading="loading"
        @submit.native.prevent
      >
        <el-form-item label="用户类型：" prop="type" v-if="!isEdit">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">会员</el-radio>
            <el-radio :label="1">游客</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="form.type === 0 && !isEdit"
          label="选择会员："
          prop="user_id"
        >
          <el-button type="primary" @click="openSelector = true">{{
            this.form.name ? this.form.name : "请选择"
          }}</el-button>
          <MemberSelector
            :selectType="0"
            v-model="openSelector"
            @selectMember="selectMember"
          ></MemberSelector>
        </el-form-item>
        <el-form-item v-if="form.type === 0 && isEdit" label="会员名称：">
          {{ form.user_name }}
        </el-form-item>
        <el-form-item v-if="form.type === 0 && isEdit" label="会内职务：">
          {{ form.title_name | placeholder }}
        </el-form-item>
        <el-form-item v-if="form.type" label="用户名称：" prop="name">
          <!-- <el-input v-model="form.name"></el-input> -->
          {{ form.name }}
        </el-form-item>
        <el-form-item v-if="form.type" label="手机号码：" prop="phone">
          <!-- <el-input v-model="form.phone"></el-input> -->
          {{ form.phone }}
        </el-form-item>
        <el-form-item label="座位号：" prop="seat_number">
          <el-input v-model="form.seat_number"></el-input>
        </el-form-item>
        <el-form-item label="用餐桌位号：" prop="table_number">
          <el-input v-model="form.table_number"></el-input>
        </el-form-item>
        <el-form-item label="更多座位备注：" prop="seat_remarks">
          <el-input v-model="form.seat_remarks"></el-input>
        </el-form-item>
        <el-form-item label="所属会场：" prop="place_id">
          <el-select
            v-if="placeOptions.length"
            v-model="form.place_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in placeOptions"
              :key="item.address"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.place_id" label="会场地址：">{{
          form.place_address
        }}</el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { save, placeList } from "../../api/activity-ranking/list";
import MemberSelector from "./MemberSelector";
export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      default: false,
    },
    // 当前的form
    currentItem: {
      type: Object,
      require: true,
    },
  },
  components: { MemberSelector },
  created() {},

  watch: {},

  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入用户名称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入用户手机号", trigger: "blur" },
        ],
        user_id: [{ required: true, message: "请选择会员", trigger: "change" }],
        place_id: [
          { required: true, message: "请选择会场", trigger: "change" },
        ],
      },
      loading: false,
      openAddressSelector: false,
      openSelector: false,
      placeOptions: [],
    };
  },
  watch: {
    "form.place_id": {
      handler(val) {
        console.log(val);
        if (val) {
          if (this.placeOptions.length) {
            let array = this.placeOptions.filter((item) => item.id === val);
            if (array.length) {
              console.log(array);
              this.form.place_address = array[0]?.address || "";
            } else {
              this.form.place_id = "";
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
    form: {
      get() {
        return this.$props.currentItem;
      },
      set(val) {
        this.$emit("changeItem", val);
      },
    },
    isEdit() {
      return this.form.id != 0;
    },
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          save({ ...this.form, activity_id: this.$route.params.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
              this.close();
              this.$emit("update");
            })
            .catch((err) => {
              this.loading = false;
            });
          //   this.close();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openDialogFn() {
      this.getList();
    },
    handleChangeAddress(data) {
      this.form = data;
    },
    selectMember(data) {
      this.form.user_id = data.user_id;
      this.form.name = data.nickname;
      console.log(data);
    },
    getList() {
      this.loading = true;
      placeList({ activity_id: this.$route.params.id })
        .then((res) => {
          this.loading = false;
          this.placeOptions = res.data;
          if (!this.placeOptions.length) {
            this.$message.info("请先添加会场地址");
          }
          let array = this.placeOptions.filter((item) => item.id === val);
          console.log(array);
          this.address = array[0]?.address || "";
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.img-tips {
  margin-top: 10px;
  font-size: 12px;
  color: #b0b0b0;
}
</style>
