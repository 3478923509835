<template>
  <div class="content-box">
    <el-dialog
      title="会场"
      :visible="is_show"
      width="600px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialogFn"
    >
      <el-form
        size="medium"
        class="medium-form"
        :model="form"
        :rules="rules"
        label-width="90px"
        ref="form"
        @submit.native.prevent
      >
        <el-form-item label="会场名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-button
            type="primary"
            @click="openAddressSelector = true"
            style="max-width: none"
            >{{ form.address ? form.address : "请选择" }}</el-button
          >
          <AddressSelector
            v-model="openAddressSelector"
            @change="handleChangeAddress"
            :detailForm="form"
          />
        </el-form-item>
        <el-form-item label="会场布置图：" prop="image">
          <SingleMediaWall v-model="form.image" :width="194" :height="135" ratio="343:184" :show-cut="true">
            <p slot="info" class="img-tips" style="width: 192px">
              建议上传尺寸比例为343*184
            </p>
          </SingleMediaWall>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import { savePlace } from "../../api/activity-ranking/list";
import AddressSelector from "@/modules/common/components/AddressSelector.vue";

export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      default: false,
    },
    // 当前的form
    currentItem: {
      type: Object,
      require: true,
    },
  },
  components: { SingleMediaWall, AddressSelector },
  created() {},

  watch: {},

  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入会场名称", trigger: "blur" }],
        address: [
          { required: true, message: "请选择会场地址", trigger: "change" },
        ],
        image: [
          { required: true, message: "请选择会场布置图", trigger: "blur" },
        ],
      },
      loading: false,
      openAddressSelector: false,
    };
  },
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
    form: {
      get() {
        return this.$props.currentItem;
      },
      set(val) {
        this.$emit("changeItem", val);
      },
    },
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          savePlace({ ...this.form, activity_id: this.$route.params.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
              this.close();
              this.$emit("update");
            })
            .catch((err) => {
              this.loading = false;
            });
          //   this.close();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.close();
    },
    openDialogFn() {},
    handleChangeAddress(data) {
      this.form = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-tips {
  margin-top: 10px;
  font-size: 12px;
  color: #b0b0b0;
}
.place-box {
  padding: 10px;
  background-color: #f2f3f5;
  .place-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  .place-item + .place-item {
    margin: 10px 0;
  }
}
</style>
