import api from "@/base/utils/request";

import fetchFile from "@/base/utils/download";
import filesUpload from "@/base/utils/upload4";
// 排位列表
export const getActivityPlaceList = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/index",
    method: "post",
    data,
  });
};
//保存用户排位信息
export const save = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/save",
    method: "post",
    data,
  });
};
// 删除信息
export const deleteRow = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/delete",
    method: "post",
    data,
  });
};
// 会场数据列表
export const placeList = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/placeList",
    method: "post",
    data,
  });
};
// 保存会场数据
export const savePlace = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/savePlace",
    method: "post",
    data,
  });
};
// 会场排序
export const saveSort = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/saveSort",
    method: "post",
    data,
  });
};
// 删除会场数据
export const delPlace = (data) => {
  return api({
    url: "/admin/cyc_activity/activityPlace/delPlace",
    method: "post",
    data,
  });
};
// 排位列表导出
export const exportInviteUser = (data) => {
  return fetchFile({
    url: "/admin/cyc_activity/activityPlace/exportIndex",
    method: "post",
    data,
    download: true,
  });
};
// 下载导入模板
export const placeImportXlsx = (data) => {
  console.log(data)
  return fetchFile({
    url: "/admin/cyc_activity/activityPlace/placeImportXlsx",
    method: "post",
    download: true,
    data: data,
  });
};


// 上传发票
export const placeImport = (data, extra) => {
  return filesUpload({
    url: '/admin/cyc_activity/activityPlace/placeImport',
    data,
    extra,
    typeArr: ["file"]
  });
}