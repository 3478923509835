/**
 * 离开列表页返回保留查询参数
 *
 * 缺点：
 * 1、表单对象暂时固定只能叫 filterForm
 * 2、列表获取方法暂时固定只能叫 getList
 * 3、使用了 ListLayout 组件的列表页面没有被考虑在内
 */
import { randomString } from '@/base/utils/tool'

export default {
  data() {
    return {
      sessionStorageList: [],

      /* 组件必须有的属性 */
      mixin__sessionStorageKey: '',
      pageData: {},
      // filterForm: {}, // 不一定是 data，也可能是 prop，如：ListLayout，也可能是计算属性
    }
  },
  watch: {
    '$route.query': {
      handler(val, oldVal) {
        if (val.q === undefined && oldVal.q) {
          if (this._filterForm) {
            // 不管了，让用户自己重置吧
            // this.getList({
            //   ...JSON.parse(this._filterForm), // 默认第一次访问的参数，由于仅query变化，页面组件不会再created
            // })
          }
        }
      },
    },
  },
  /**
   * Hook functions with the same name are merged into an array so that all of them will be called.
   * Mixin hooks will be called before the component’s own hooks.
   */
  created() {
    if (this.$props?.storageFilterForm === false) {
      return // 希望不影响未适配或不需要适配的列表（仅针对使用了 ListLayout 的页面）
    }

    if (!this.mixin__sessionStorageKey) {
      // 新做法，用来在列表编辑前保留列表查询参数
      this._filterForm = JSON.stringify(this.filterForm) // 备份 created 时参数

      if (this.$props?.filterForm === undefined) {
        // 不是 ListLayout
      } else {
      }

      const { path, query } = this.$route

      if (query.q) {
        // 适配 resetFields() 重置的内容为 sessionStorage 内容，这不应该
        // 可能是 filter 初始化时，filterForm 已经不是默认"空"值
        this.$nextTick(() => {
          console.log('mixin created nextTick')

          this.mixin__created() // 终于可以修改筛选参数而不影响 resetFields() 了
        }) // 等待 Filter 组件 created
      } else {
        this._storage_key = randomString(16) // 页面唯一key

        this.$router.replace({
          path,
          query: { ...query, q: this._storage_key },
        })

        // 默认参数
        this.getList(this.filterForm)
      }

      return
    }

    // 旧的做法，没看懂又不敢删
    const query = sessionStorage.getItem(this.mixin__sessionStorageKey)
    this.getList(query ? JSON.parse(query) : this.pageData) // 获取列表数据
  },
  mounted() {
    const that = this
    window.onbeforeunload = function (e) {
      console.log('on before unload')
      that.mixin__beforeLeaveList()
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null
  },
  beforeRouteLeave(to, from, next) {
    this.mixin__beforeRouteLeave(to, from, next)
  },
  methods: {
    mixin__created() {
      const { path, query } = this.$route

      if (query.q) {
        this._storage_key = query.q
        console.log('session key: ', query.q)
        const queryForm = sessionStorage.getItem(query.q)

        if (queryForm) {
          console.log('session value:', queryForm)
          const overrideForm = JSON.parse(queryForm)

          if (this.$props?.filterForm !== undefined) {
            console.log('is in list layout')

            this.filterForm.page = 1 // 有好多列表的 filterForm 缺少这个

            // 警告：Avoid mutating a prop directly
            Object.keys(overrideForm).forEach((qf) => {
              if (this.filterForm[qf] !== undefined) {
                if (overrideForm[qf] !== undefined) {
                  this.filterForm[qf] = overrideForm[qf] // 覆盖
                }
              }
            })
          } else this.filterForm = overrideForm
        }

        // if (this.$props?.filterForm !== undefined) {
        this.getList(this.filterForm)
        // }
      } else {

      }
    },
    // 使用了 ListLayout 的页面组件，需要额外监听 beforeRouteLeave 并转发至此函数
    mixin__beforeRouteLeave(to, from, next) {
      // console.log('before route leave')

      // called when the route that renders this component is about to be navigated away from.
      // As with `beforeRouteUpdate`, it has access to `this` component instance.
      if (!this.sessionStorageList.includes(to.name)) {
        sessionStorage.setItem(this.mixin__sessionStorageKey, '')
      }

      // 新的做法，写入 sessionStorage

      // 将查询参数保留至 session
      // const storageKey = randomString(16) // 随机一个key给它
      const storageKey = this._storage_key

      // 动态修改貌似不一定成功的
      this.$route.query.q = storageKey // 直接修改地址栏参数，用户从其他页面返回这里的时候参数不会消失
      // from.query.q = storageKey // 直接修改地址栏参数，用户从其他页面返回这里的时候参数不会消失

      if (storageKey) {
        const baseData =
          this.tenantVersion === 'school' ? this.schoolForm : this.filterForm
        // list 接口返回的 data 结构
        const { data, ...others } = this.pageData || {
          data: [],
          current_page: 1,
        }
        sessionStorage.setItem(
          storageKey,
          JSON.stringify({
            ...baseData,
            ...others,
            // page: others.current_page,
          })
        )

        // console.log('0000000000000000000000')
        // console.log('storage key: ')
        // console.log(storageKey)
        // // console.log('storage result: ')
        // // console.log(sessionStorage.getItem(storageKey))
        // console.log('0000000000000000000000')
      }

      next()
    },

    mixin__beforeLeaveList(routeName) {
      // list 接口返回的 data 结构
      const { data, ...others } = this.pageData

      /* tenantVersion 是计算属性 */
      const baseData =
        this.tenantVersion === 'school' ? this.schoolForm : this.filterForm
      sessionStorage.setItem(
        this.mixin__sessionStorageKey,
        JSON.stringify({
          ...baseData,
          ...others,
          page: others.current_page,
        })
      )
      if (routeName) this.sessionStorageList.push(routeName)
    },
    /* 组件必须有的方法 */
    getList() {},
  },
}
